<template>
 
 <InstallIpa msg="Welcome to Your Vue.js App"/>
</template>

<script>
import InstallIpa from './components/installIpa.vue'

export default {
  name: 'App',
  components: {
    InstallIpa
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .app_version{
    text-align: left;
  }

  .container_app{
    /* flex-direction: row; */
    padding: 5px;
  }

  .center-container-cer2{
    background-color: white;
    width:70%;
    min-width: 340px;
    height:560px;
  }

 .background2 {
    width: 100%;
    height: 1000px;
    background-color: #348097;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

 .background6 {
    width: 100%;
    height: 800px;
    background-color: rgb(70, 70, 71);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

</style>
