import axios from 'axios'
import {Loading, Message} from 'element-ui'
import Vue from "vue";


var domain = "https://www.dumpapp.com/api";

// create an axios instance
const service = axios.create({
  baseURL: domain,
    // baseURL: 'http://120.53.234.71:1995/api',
    // baseURL: 'http://127.0.0.1:1995/api',
    timeout: 30000,
    withCredentials:true,
    crossDomain:true,
})

let loading
let isLoginUrl

// request interceptor
service.interceptors.request.use(
    config => {
    config.headers['Content-Type'] = 'application/json'
    // config.headers['Ops-Auth-Name'] = 'x-auth-headerv2'
        loading = Loading.service({
          body: true,
          lock: true,
          text: '拼命加载中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.6)'
        })
        return config
    },
    error => {
      return Promise.reject(error)
    }
)
  
  // response interceptor
  service.interceptors.response.use(
    response => {
      loading.close()
      if (response.status === 200 && isLoginUrl) {
        window.location.href = `${window.location.origin}`
      }
      if (typeof response.data === 'string') {
          console.log(response.headers)
        Message({
          message: "success",
          type: 'success',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
        })
      }
      return response.data
    },

    error => {
      loading.close()
      let message
      if (error.response) {
        switch (error.response.status) {
          case 401:
            console.log("error object1");
            if (typeof error.response.data === 'string') {
              message = error.response.data
            } else if (typeof error.response.data === 'object') {
              message = error.response.data.message || error.response.data.error.message
            }
            Message({
              message: message,
              type: 'error',
              duration: 1.5 * 1000,
              offset:document.body.clientHeight * 0.5 - 100
            }) 
            return
          case 403:
            console.log("error object2");
            
        console.log("error object3" );

        if (typeof error.response.data === 'string') {
          message = error.response.data
        } else if (typeof error.response.data === 'object') {
          message = error.response.data.message || error.response.data.error.message
        }

        message = message || '服务器端错误'
        Message({
          message: message,
          type: 'error',
          duration: 1.5 * 1000,
          offset:document.body.clientHeight * 0.5 - 100
        })
            return error.response.data;
        }
        console.log("error object3" );
        if (typeof error.response.data === 'string') {
          message = error.response.data
        } else if (typeof error.response.data === 'object') {
          message = error.response.data.message || error.response.data.error.message
        }
      } else {
        message = error.message
      }
      message = message || '服务器端错误'
      Message({
        message: message,
        type: 'error',
        duration: 1.5 * 1000,
        offset:document.body.clientHeight * 0.5 - 100
      })

      return Promise.reject(error)
    }
  )

export default service
