import request from '@/utils/request'

/// 登录
export function getSelf () {
    return request({
      url: '/member/self',
      method: 'get',
    })
}

export function getSignedApp(id, isPlist) {
  if (isPlist) {
    return request({
      url: '/sign_ipa/' + id + "?include=plist_url",
      method: 'get'
    })
  } else { 
    return request({
      url: '/sign_ipa/' + id,
      method: 'get'
    })
  }
}
