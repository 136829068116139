/*
 * @Author: zhangdasen zhangdasen@conew.com
 * @Date: 2022-04-14 00:02:01
 */
import request from '@/utils/request'

export function deleteCoin(fileId) {
  var data = {
    "id":fileId,
    "expense_type":"sign_ipa"
  }
  return request({
    url: '/dispense/expense',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:data
  })

}


