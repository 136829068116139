<!--
 * @Author: your name
 * @Date: 2022-01-09 15:45:50
 * @LastEditTime: 2022-09-04 22:38:58
 * @LastEditors: zhangdasen zhangdasen@conew.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /smash_web/src/views/installIpa.vue
-->
<template>

<div class="body" ontouchstart="" onmouseover="" style="cursor: pointer" >
    <van-overlay :show="show" @click="show = false">
    <div class="wrapper2" @click.stop>
      <label style="color:azure;font-size:19px;font-weight:bold"> 请点击右上角按钮，在弹出的菜单中，选择 在safari(默认浏览器)中打开! </label>
    </div>
  </van-overlay>

  <div class="center-container-ipa" v-if="isMoreIpa">
<el-form>
  <div class="container_install">
    <template>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom:20px">
        <el-table-column
          prop="name"
          label="名字">
        </el-table-column>
        <el-table-column
          prop="version"
          label="版本">
        </el-table-column>
        <el-table-column
        prop="link"
        label="生成二维码">
        <template slot-scope="scope">
          <el-button @click="copyLink(scope.row.fileId)" type="warning" icon="el-icon-document-copy" circle></el-button>
         </template>
        </el-table-column>
        <el-table-column
        prop="download"
        label="安装">
        <template slot-scope="scope">
          <el-button @click="instalIPA(scope.row.fileId)" type="success" icon="el-icon-download" circle></el-button>
         </template>
        </el-table-column>
        
      </el-table>
    </template>
  </div>
</el-form>
</div>
    <div class="mobile-wrap center">
      <main>
        <div class="appItem">
          <div class="left"><img src="https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/2c/15/d8/2c15d83f-49f8-712c-d77f-f7a01f8e6c04/AppIcon-0-1x_U007emarketing-0-0-0-7-0-0-P3-85-220-0.png/512x512bb.jpg" alt="" /></div>
          <div id="qrcode" class="qrcode" ref="qrcodeContainer"></div> 
          <div class="right">
            <strong>{{this.tableData[0].name}}<span>1+</span></strong>
            <p>第 1 名</p>
            <div class="installBox">
              <a class="down" href="javascript:;" @click="installFree">安装</a>
              <a class="down2" href="javascript:;" v-if="!isMoreIpa" @click="gesQrCode">二维码</a>
            </div>
          </div>
          <div class="appTip">
            <div class="score">
              <div class="star">4.9 <var></var></div>
              <p>19k 个评分</p>
            </div>
            <div class="centerBox"><i>#</i>4</div>
            <div class="age">
              <b>18+</b>
              <p>年龄</p>
            </div>
          </div>
        </div>
        <div class="comment">
          <strong class="publicTitle">评分及评论</strong>
          <div class="left">
            <b>4.9</b>
            <p>满分 5 分</p>
          </div>
          <div class="right">
            <div class="star_row">
              <span class="s1"><i></i></span>
              <div class="lineBox"><var class="v1"></var></div>
            </div>
            <div class="star_row">
              <span class="s2"><i></i></span>
              <div class="lineBox"><var class="v2"></var></div>
            </div>
            <div class="star_row">
              <span class="s3"><i></i></span>
              <div class="lineBox"><var class="v3"></var></div>
            </div>
            <div class="star_row">
              <span class="s4"><i></i></span>
              <div class="lineBox"><var class="v4"></var></div>
            </div>
            <div class="star_row">
              <span class="s5"><i></i></span>
              <div class="lineBox"><var class="v5"></var></div>
            </div>
            <p>19k 个评分</p>
          </div>
        </div>
        <div class="newFunction">
          <strong class="publicTitle">新功能</strong>
          <p>{{this.tableData[0].version}}</p>
        </div>
        <div class="appInfo">
          <strong class="publicTitle">信息</strong>
          <div class="box">
            <ul>
              <li>
                <span>大小</span>
                <p>40 MB</p>
              </li>
              <li>
                <span>兼容性</span>
                <p>
                  Android系统 或 iOS 8.0 或更高版本。与 iPhone、iPad 和 iPod
                  touch 兼容。
                </p>
              </li>
              <li>
                <span>语言</span>
                <p>英语,简体中文</p>
              </li>
              <li>
                <span>年龄分级</span>
                <p>限18岁以上</p>
              </li>
              <li>
                <span>Copyright</span>
                <p>© 2018 MobiFun Games Inc</p>
              </li>
              <li>
                <span>价格</span>
                <p>免费</p>
              </li>
              <li>
                <span>隐私政策</span>
                <p>✋</p>
              </li>
            </ul>
          </div>
        </div>
      </main>


      <div class="footer">
        <p class="p2">
          仅供测试，禁止用于非法用途。
        </p>
      </div>
      <div class="pup">
        <div class="guide">
          <i class="colse"></i>
          <div class="pics">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="pic">
                    <img src="../assets/0df0c_0_600_411.jpg" alt="" />
                  </div>
                  <div class="text">
                    安装引导<br />第一步 允许打开配置描述文件
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="pic">
                    <img src="../assets/9179e_3_600_411.jpg" alt="" />
                  </div>
                  <div class="text">
                    安装引导<br />第二步 点击右上角安装按钮
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="pic">
                    <img src="../assets/d3c74_2_600_411.jpg" alt="" />
                  </div>
                  <div class="text">安装引导<br />第三步 输入开机解锁密码</div>
                </div>
                <div class="swiper-slide">
                  <div class="pic">
                    <img src="../assets/0665a_1_600_411.jpg" alt="" />
                  </div>
                  <div class="text">安装引导<br />第四步 点击下方安装按钮</div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="smallTip"><a href="#">什么是描述文件？</a></div>
        </div>
      </div>
      <div class="pupPic">
        <img src="../assets/5cbc4_5_1242_2007.png" alt="" />
      </div>
    </div>

  </div>
</template>


<style>
@import "../css/reset.css";
@import "../css/style.css";
@import "../css/swiper.min.css";
:root {
  font-size: 54px;
}

.container_install{
  flex-direction: row;
  padding: 10px;
}

.center-container-ipa {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
}

.wechat-ios,
.wechat-android {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
  overflow: hidden;
}
.wechat-ios img,
.wechat-android img {
  width: 100%;
  height: 100%;
}
.wechat-mask .mask-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}
.wechat-mask .mask-pop {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 300px;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.wechat-mask .copy-url-img {
  display: block;
  width: 100%;
}
.wechat-mask .copy-url {
  position: relative;
  margin: 20px 30px;
  height: 36px;
  line-height: 36px;
  background: #f1f6f9;
  border-radius: 18px;
  overflow: hidden;
}
.wechat-mask .copy-url input {
  padding-left: 20px;
  color: #9a9a99;
}
.wechat-mask .copy-url button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  background: linear-gradient(
    90deg,
    rgba(34, 125, 249, 1),
    rgba(0, 203, 250, 1)
  );
  color: #fff;
  border-radius: 0 18px 18px 0;
}
.wechat-mask {
  z-index: 2;
  position: relative;
  display: block;
}

.down2 {
  min-width: 2.1rem;
  background-color: #fe0101;
  -webkit-border-radius: 0.3rem;
          border-radius: 0.3rem;
  text-align: center;
  color: #fff;
  font-size: 0.28rem;
  padding: 0 0.15rem;
  height: 0.62rem;
  line-height: 0.62rem;
}
</style>



<script>

import {getSignedApp} from '@/api/member'
import {deleteCoin} from '@/api/install'

import { Dialog } from 'vant';
import {Message} from 'element-ui'
import QRCode from 'qrcodejs2'
import {Loading} from 'element-ui'

export default {
  data() {
    return {
      udid: '点击按钮，立即安装APP',
      password: '',
       dialogVisible: false,
       fits: ['fill'],
       options: [],
       value: '',
       loading :null,
       loading_edit: true,
       userData : null,
       code:"",
       ipaUrl:"",
       installURL:"",
       fileId:"",
       ids:[],
       idsData:[],
       tableData: [],
       show:false,
       isMoreIpa:false
    }
  },

  methods: {
     checkIsWeChat(){
        if (navigator.userAgent.indexOf("MicroMessenger") !== -1) {
            alert("请点击右上角按钮，在弹出的菜单中，选择 在safari(默认浏览器)中打开!");
            return true;
        }else{
            return false;
        }
    },

    installFree(){
      this.instalIPA(this.tableData[0].fileId);
    },


    gesQrCode(){
      this.copyLink(this.tableData[0].fileId);
    },

     creatQrCode() {
      document.getElementById("qrcode").innerHTML = "";
      new QRCode(this.$refs.qrcodeContainer, {  
          text: this.ipaUrl,  
          width: 200,  
          height: 200,
          colorDark: '#000000',  
          colorLight: '#ffffff',  
          correctLevel: QRCode.CorrectLevel.H  
      });
    },

    async requestIPA(id){

      this.ids = id.split(',');

      this.isMoreIpa = this.ids.length > 1 ? true : false;
      for (var i=0;i<this.ids.length; i++){

        var requestId = this.ids[i];
        var data = await getSignedApp(requestId,false);

        var name = data.ipa_name;
        var version = data.ipa_version;
        var fileId = data.id;

        var newDic = {}
        newDic["name"]  = name;
        newDic["version"]  = version;
        newDic["fileId"]  = fileId;
        this.tableData.push(newDic);
      }
    },

    // 扣费接口
    async deleteCoinMethod(fileId,installURL) {
      var res = await deleteCoin(fileId);
      if (res != null && res != undefined) {
        localStorage.setItem(installURL, "1");
      }
    },

    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = decodeURI(window.location.search.substr(1)).match(reg);
        if (r != null)return unescape(r[2]);
        return null;
    },


    async copyLink(fileId){
      this.ipaUrl = "https://install.hxios.cn/?id=" + fileId;
      this.creatQrCode();
      this.$copyText(this.ipaUrl ).then(
      (res) => {
      Message({
        message:"链接已复制！",
            type: 'success',
            duration: 1 * 1000,
            offset:document.body.clientHeight * 0.5 - 100
          })
      },
      (err) => {
      }
    );

    },

    installApp(fileId,installURL){
      window.location.href = installURL;
      var saveState = window.localStorage.getItem(installURL);
      if (saveState != "1"){
        this.deleteCoinMethod(fileId,installURL);
        console.log("扣费成功");
      }else{
        console.log("没扣费");
      }
    },

   async instalIPA(fileId){
    var data = await getSignedApp(fileId, true);
        if (data != undefined){
            if(data.error != undefined){
              Message({
                  message: data.error.messae,
                  type: 'error',
                  duration: 2 * 1000
              })
            }else{
              var plistUrl = data.plist_url_v2;
              if (plistUrl == undefined){
                plistUrl = data.plist_url;
              }
              if (plistUrl != undefined){
                Dialog.confirm({
                  title: '提示',
                  message:
                    '是否确认进行安装？',
                }).then(() => {
                 var options = {
                    body: true,
                    lock: true,
                    text: '正在读取中......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.6)'
                  }

                  var installURL = "itms-services:\/\/?action=download-manifest&url=" + plistUrl;
                  let loadingInstance = Loading.service(options);
                  var tThis = this;
                  setTimeout(function() {
                    loadingInstance.close();
                    tThis.installApp(fileId,installURL);
                  }, 2000);
                })
              }
             }
        }else{
             Message({
                  message: data.error.messae,
                  type: 'error',
                  duration: 2 * 1000
              }) 
        }
    }
  },

  mounted() {
    var appID = this.getQueryString("id");
    this.requestIPA(appID);

    this.show =  this.checkIsWeChat();
    
  },
}
</script>
